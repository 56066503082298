import React, { useState } from 'react';
import SpotifyWebApi from 'spotify-web-api-node';
import './Search.css';
import { AlbumPreview, SongPreview, ArtistPreview } from './PreviewComponents';

const spotifyWebApi = new SpotifyWebApi({
  clientId: "aab0ed624a604822b7a5dbffef2916bd"
});

const SearchBar = ({ accessToken, setCurrentSong }) => {
  const [query, setQuery] = useState('');
  const [preview, setPreview] = useState([]);

  spotifyWebApi.setAccessToken(accessToken);

  const handleSearch = async (inputValue) => {
    setQuery(inputValue);

    try {
      const response = await spotifyWebApi.search(inputValue, ['track', 'artist', 'album'], { limit: 2 });
      const { tracks, artists, albums } = response.body;
      const sortedTracks = tracks.items.sort((a, b) => b.popularity - a.popularity);
      const sortedArtists = artists.items.sort((a, b) => b.popularity - a.popularity);
      const sortedAlbums = albums.items.sort((a, b) => b.popularity - a.popularity);
      const previewResults = [...tracks.items, ...artists.items, ...albums.items];
      setPreview(previewResults);
      console.log(previewResults);
    } catch (error) {
      console.log('Error searching:', error);
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
    handleSearch(query);
  };
  
  return (
    <div className="search-bar-container">
      <form className="search-bar" onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Search Songs/Artists/Albums"
          onChange={event => handleSearch(event.target.value.trim())}
          className="search-bar__input"
        />
        <button type="submit" className="search-bar__button">
          Search
        </button>
      </form>
      {preview.length > 0 && (
        <div className="search-preview">
          <h4>Preview:</h4>
          <ul>
            {preview.map(item => {
              if (item.type === 'track') {
                return <SongPreview song={item} setCurrentSong={setCurrentSong} key={item.id} />;
              } else if (item.type === 'artist') {
                return <ArtistPreview artist={item} key={item.id} />;
              } else if (item.type === 'album') {
                return <AlbumPreview album={item} key={item.id} />;
              } else {
                return null;
              }
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SearchBar;
