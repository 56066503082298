import { useEffect, useState } from 'react';
import SpotifyPlayer from 'react-spotify-web-playback';
import './MusicPlayer.css';
export default function Player({ accessToken, song }) {
  const [play, setPlay] = useState(false);
  const [isBottom, setIsBottom] = useState(false);

  useEffect(() => setPlay(true), [song]);

  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollBottom = scrollTop + windowHeight;

    setIsBottom(scrollBottom === documentHeight);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className={`player-container ${isBottom ? 'bottom' : ''}`}>
      {accessToken && (
        <div className="player-content">
        <SpotifyPlayer
          token={accessToken}
          callback={(state) => {
            if (!state.isPlaying) setPlay(false);
          }}
          play={play}
          showSaveIcon
          uris={song ? [song] : []}
          styles={{
            bgColor: '#333',
            color: '#FFFFFF',
            activeColor: '#1DB954',
            trackNameColor: '#FFFFFF',
            trackArtistColor: '#B3B3B3',
            sliderColor: '#1DB954', 
            sliderHandleColor: '#FFFFFF',
            loaderColor: '#1DB954',
            trackNameColor: '#1DB954',
          }}
        />
        </div>
      )}
    </div>
  );
}
