import React from 'react';

const Album = ({ album }) => {
  return (
    <li key={album.album.id} className="dashboard__item">
      <img
        src={album.album.images[0].url}
        alt="Album"
        className="dashboard__album-image"
        draggable="false"
      />
      <p className="dashboard__album-title">{album.album.name}</p>
      <p className="dashboard__item-info dashboard__item-info--bold">
        Artists: {album.album.artists.map(artist => artist.name).join(', ')}
      </p>
      <p className="dashboard__item-info">Release Date: {album.album.release_date}</p>
    </li>
  );
};

export default Album;
