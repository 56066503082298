import React from 'react';
import './Login.css';

const link = 'https://accounts.spotify.com/authorize?';
const client_id = 'aab0ed624a604822b7a5dbffef2916bd';
const redirect_uri = 'https://musica-90m.pages.dev';
const scope =
  '&scope=streaming%20user-read-email%20user-read-private%20user-library-read%20user-library-modify%20user-read-playback-state%20user-modify-playback-state';

const AUTHURL = link + 'client_id=' + client_id + '&response_type=code&redirect_uri=' + redirect_uri + scope;

export default function Login() {
  console.log(AUTHURL)
  return (
    <div className="login-container">
      <a className="login-button" href={AUTHURL}>
        Login with Spotify
      </a>
    </div>
  );
}
