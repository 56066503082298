import React, { useState } from 'react';
import Login from './Login';
import Dashboard from './Dashboard';
import useAuth from './useAuth';
import Player from './Player';

const code = new URLSearchParams(window.location.search).get('code');

function App() {
  const accessToken = useAuth(code);
  const [currentSong, setCurrentSong] = useState(null);

  return code ? (
    <>
      <Dashboard code={code} accessToken={accessToken} currentSong={currentSong} setCurrentSong={setCurrentSong} />
      <Player accessToken={accessToken} song={currentSong} />
    </>
  ) : (
    <Login />
  );
}

export default App;
