import { useEffect, useState } from 'react';
import axios from 'axios';
import './Profile.css';
import Album from './Album';
import Song from './Song';
import SearchBar from './SearchBar';

export default function Dashboard({ code, accessToken, currentSong, setCurrentSong }) {
  const [userInfo, setUserInfo] = useState(null);
  const [albums, setAlbums] = useState([]);
  const [songs, setSongs] = useState([]);

  const playSong = (song) => {
    setCurrentSong(song);
  };

  useEffect(() => {
    if (accessToken) {
      const fetchData = async () => {
        try {
          const [userInfoResponse, albumsResponse, songsResponse] = await Promise.all([
            axios.get('https://api.spotify.com/v1/me', {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }),
            axios.get('https://api.spotify.com/v1/me/albums', {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }),
            axios.get('https://api.spotify.com/v1/me/tracks', {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }),
          ]);

          setUserInfo(userInfoResponse.data);
          setAlbums(albumsResponse.data.items);
          setSongs(songsResponse.data.items);
        } catch (error) {
          console.log('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [accessToken]);

  return (
    <div className="dashboard">
      <SearchBar accessToken={accessToken} setCurrentSong={setCurrentSong} />
      {userInfo ? (
        <div>
          <h1 className="dashboard__title">Welcome, {userInfo.display_name}</h1>
          <img
            src={userInfo.images[0].url}
            alt="Profile"
            className="dashboard__profile-image"
            draggable="false"
          />
          <p className="dashboard__info">Followers: {userInfo.followers.total}</p>
          <p className="dashboard__info">Country: {userInfo.country}</p>
          <p className="dashboard__info">Version: {userInfo.product}</p>
          <h2 className="dashboard__section-title">Albums</h2>
          <ul className="dashboard__list">
            {albums.map(album => (
              <Album album={album} key={album.album.id} />
            ))}
          </ul>

          <h2 className="dashboard__section-title">Songs</h2>
          <ul className="dashboard__list">
            {songs.map(song => (
              <Song song={song} playSong={playSong} key={song.track.id} />
            ))}
          </ul>
        </div>
      ) : (
        <div>Loading user information...</div>
      )}
    </div>
  );
}
