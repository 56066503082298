import React from 'react';



const formatDuration = durationMs => {
  const minutes = Math.floor(durationMs / 60000);
  const seconds = ((durationMs % 60000) / 1000).toFixed(0);
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

const SongPreview = ({ song, setCurrentSong }) => {
  const handleClick = () => {
    setCurrentSong(song.uri);
  };

  return (
    <li key={song.id} className="search-preview__item" onClick={handleClick}>
      {song.album.images[0]?.url && (
        <img
          src={song.album.images[0].url}
          alt="Song"
          className="search-preview__song-image"
          draggable="false"
        />
      )}

      <div className="search-preview__details">
        <div>
          <p className="search-preview__song-title">{song.name}</p>
          <p className="search-preview__artist-name">{song.artists.map(artist => artist.name).join(', ')}</p>
        </div>
        <p className="search-preview__item-info search-preview__item-info--time">{formatDuration(song.duration_ms)}</p>
      </div>
    </li>
  );
};

const ArtistPreview = ({ artist }) => {
  return (
    <li key={artist.id} className="search-preview__item">
      {artist.images[0]?.url && (
        <img
          src={artist.images[0].url}
          alt="Song"
          className="search-preview__song-image"
          draggable="false"
        />
      )}

      <div className="search-preview__details">
        <div>
          <p className="search-preview__song-title">{artist.name}</p>
          <p className="search-preview__artist-name">{artist.genres.join(', ')}</p>
        </div>
        <p className="search-preview__item-info search-preview__item-info--followers">Followers: {artist.followers.total}</p>
      </div>
    </li>
  );
};


const AlbumPreview = ({ album }) => {
  return (
    <li key={album.id} className="search-preview__item">
      {album.images[0]?.url && (
        <img
          src={album.images[0].url}
          alt="Song"
          className="search-preview__song-image"
          draggable="false"
        />
      )}

      <div className="search-preview__details">
        <div>
          <p className="search-preview__song-title">{album.name}</p>
          <p className="search-preview__artist-name">{album.artists.map(artist => artist.name).join(', ')}</p>
        </div>
        <p className="search-preview__item-info search-preview__item-info--date">{album.release_date}</p>
      </div>

    </li>
  );
};

export { AlbumPreview, SongPreview, ArtistPreview };
