import React from 'react';

const Song = ({ song, playSong }) => {
  return (
    <li key={song.track.id} className="dashboard__item">
      <img
        src={song.track.album.images[0].url}
        alt="Song"
        className="dashboard__song-image"
        draggable="false"
      />
      <p className="dashboard__song-title">{song.track.name}</p>
      <p className="dashboard__item-info dashboard__item-info--bold">
        Artists: {song.track.artists.map(artist => artist.name).join(', ')}
      </p>
      <p className="dashboard__item-info">Album: {song.track.album.name}</p>
      <button
        className="dashboard__play-button"
        onClick={() => playSong(song.track.uri)}
      >
        Play Song
      </button>
    </li>
  );
};

export default Song;
